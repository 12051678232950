<template>
    <div class="my-6">
      <h3 class="font-bold">Lista de Troca de Lote</h3>
      <span class="text-sm text-gray-500">Aqui você pode observar todos os itens relacionados aos como
        suas respectivas ações.
      </span>
    </div>
    <hr class="my-9" />
    <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
      <div class="w-full">
        <div class="flex flex-col lg:flex-row items-end">
          <div class="w-full p-2">
            <h3 class="font-bold">Informações das Trocas de Lote</h3>
            <span class="text-sm text-gray-500">
              Aqui você pode observar todos os dados relacionados a todas trocas de lotes
              existentes.
            </span>
          </div>
          <div class="mt-3">
          <router-link :to="`/lotes/criar`">
            <Button color="primary" :text="`Agendar Virada de Lote`" style="width: 190px!important;"></Button>
          </router-link>
          </div>
        </div>

 
          <div class="flex lg:flex-row flex-col items-center mt-3 p-3">
            <div class="w-full p-2">
              <Input type="datalist" id="evento" v-model="eventoId" label="Evento" placeholder="Selecione um evento"
                :data="eventos" @keyupSearch="getEvent" />
            </div>
            <div class="w-full p-2">
              <Input type="select" id="status" v-model="statusSelect" label="Status" placeholder="Selecione um Status"
                :data="statusOption" @keyupSearch="geStatus" />
            </div>
            <div class="w-full p-2">
              <Input type="select" id="status" v-model="tipoAgendamentSelect" label="Tipos de Agendamento"
                placeholder="Selecione um Tipo de Agendamento" :data="tipoAgendamentoOption" @keyupSearch="geStatus" />
            </div>
          </div>  

        <div class="flex flex-col items-end">
        <div class="justify-center">
            <Button color="primary" :text="`Filtrar`" @click="filter()"></Button>
        </div>
      </div>
      <div class="card w-full p-2" v-if="data">
        <DataTable :headers="headers" :data="data" :options="options" color="primary" />
      </div>
    </div>
</div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PUT, POST_VENDAS, POST } from "../../services/api";
import { inject, onMounted, ref } from "vue";
import moment from 'moment-timezone';
import { toDate } from "../../services/formater"
import Input from '@/components/Elements/Input.vue';
export default {
  name: 'loteIndex',
  components: {
    DataTable,
    Button,
    Input,
  },

  setup() {
    const data = ref([])
    const alert = inject("alert");
    const loader = inject("loading");
    const modal = inject("modal");

    const eventoId = ref('')
    const eventos = ref([])
    const eventosOriginal = ref([])
    const statusOption = ref([
      {
        label: 'Todos',
        value: '3'
      },
      {
        label: 'Agendado',
        value: '1'
      },
      {
        label: 'Cancelado',
        value: '0'
      },
      {
        label: 'Concluido',
        value: '2'
      }])
    const tipoAgendamentoOption = ref([
      {
        label: 'Todos',
        value: '0'
      },
      {
        label: 'Troca de Lote',
        value: '1'
      }, {
        label: 'Ativar Produto',
        value: '2'
      }, {
        label: 'Desativar Produto',
        value: '3'
      },
      {
        label: 'Parar Vendas',
        value: '4'
      },])



    const statusSelect = ref('1')
    const tipoAgendamentSelect = ref('0')

    const filtro = ref({
      "id_evento": 0,
      'status': '1'
    })


    const status = (status) => {
      switch (status) {
        case '0':
          return 'Cancelado'
        case '1':
          return 'Agendado'
        case '2':
          return 'Concluido'
      }
    }

    const getType = (value) => {

      /*TROCALOTE = '1',
        ATIVAPRODUTO = '2',
        DESATIVAPRODUTO = '3',
        OUTSTOCK = '4',
      */
      switch (value) {
        case '1':
          return 'Trocar Lote';
        case '2':
          return 'Ativar Produto';
        case '3':
          return 'Desativar Produto';
        case '4':
          return 'Terminar Venda'
      }

      return ''
    }

    function converterParaData(dataStr) {

      const [dataPart, horaPart] = dataStr.split(' ');
      const [dia, mes, ano] = dataPart.split('/');
      const [hora, minuto] = horaPart.split(':');
      return new Date(ano, mes - 1, dia, hora, minuto, 0);
    }

    const getAgendamentos = async () => {
      try {
        loader.open()
        const response = await GET('/agendamento_lote')

        const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))

        data.value = response.data.map(elemento => {
          return {
            id: elemento.id,
            detalhes: elemento.identificacao,
            evento: elemento.evento ? elemento.evento.nome : "",
            data: moment.tz(elemento.data_hora, usuarioConta.timeZone).format('DD/MM/YYYY HH:mm'),
            produtoDesativado: elemento.agendamentoProduto[0].produtoDesativado.cod_produto,
            produtoAtivado: elemento.agendamentoProduto[0].produtoAtivado.cod_produto,
            status: status(elemento.status)
          }
        }).sort((a, b) => {
          const dataA = converterParaData(a.data);
          const dataB = converterParaData(b.data);

          return dataA - dataB;
        })

        loader.close()

      } catch (error) {
        loader.close()
        alert.open("Error", "Não foi possivel recuperar o agendamento", "danger")
      }
    }

    const cancelarTroca = async (param) => {
      loader.open();

      const cancelarTrocaFunction = async function () {
        try {
          loader.open();

          await PUT(`agendamento_lote/${param.id}/cancelar`)
          getAgendamentos()
          loader.close();
          alert.open("Sucesso!", `Agendamento cancelado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      }
      modal.open(
        "Atenção",
        `Deseja Cancelar esse Agendamento?`,
        "warning",
        "Sim",
        () => cancelarTrocaFunction(),
        modal.close()
      );
      loader.close();
    }


    const forçarPlay = async (param) => {
      loader.open();

      const forçarPlayFunction = async function () {


        if (param.status !== 'Agendado') {
          alert.open("Alert", 'Agendamento Concluido ou Cancelado', "alert");
          return
        }


        try {
          loader.open();

          await POST_VENDAS(`/agendamento_lote/force`, { id: param.id })


          getAgendamentos()
          loader.close();
          alert.open("Sucesso!", `Ação realizada com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      }
      modal.open(
        "Atenção",
        `Deseja trocar Lote?`,
        "warning",
        "Sim",
        () => forçarPlayFunction(),
        modal.close()
      );
      loader.close();
    }

    const getEventos = async () => {
      try {
        const response = await GET("event");
        eventosOriginal.value = response

        response.map(evento => eventos.value.push({ label: evento.nome + " - " + toDate(evento.data), value: evento.id }));
      } catch (error) {
        alert.open("Erro", error, "danger");
      }
    }

    const filter = async () => {
      try {
        loader.open()
        const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))
        //filtar o evento original...
        const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == eventoId.value
        );


        filtro.value.status = statusSelect.value
        filtro.value['tipo_agendamento'] = tipoAgendamentSelect
        filtro.value['id_evento'] = eventoSelecionado ? eventoSelecionado.value : null

        const response = await POST('agendamento_lote/filtro', filtro.value)

        data.value = response.data.map(elemento => {

          const evento = eventosOriginal.value.find(evento => evento.id === elemento.agendamentoProduto[0].produtoDesativado.id_evento)

          return {
            id: elemento.id,
            detalhes: elemento.identificacao,
            evento: evento.nome,
            data: moment.tz(elemento.data_hora, usuarioConta.timeZone).format('DD/MM/YYYY HH:mm'),
            type: getType(elemento.tipo_agendamento),

            status: status(elemento.status)
          }
        }).sort((a, b) => {
          const dataA = converterParaData(a.data);
          const dataB = converterParaData(b.data);

          return dataA - dataB;
        })

        loader.close()
      } catch (error) {
        loader.close()
        alert.open("Erro", error, "danger");
      }
    }


    onMounted(async () => {
      await getEventos()
      await filter()

    })

    const headers = [
      { key: "Id", value: "id" },
      { key: "Status", value: "status" },
      { key: "Evento", value: "evento" },
      { key: "Detalhes", value: "detalhes" },
      { key: "Tipo", value: "type" },

      { key: "Data", value: "data", isDate: true },
      { key: "Ações", value: "" },
    ]

    const options = [
      { icon: "pen", path: "/lotes/editar", title: "Editar item" },
      { icon: "play", title: "Forçar a troca de lote", action: (param) => forçarPlay(param) },
      { icon: "trash", title: "Cancelar troca de lote", action: (param) => cancelarTroca(param) },
    ];


    return {
      data,
      headers,
      options,
      eventos,
      eventoId,
      statusSelect,
      statusOption,
      filter,
      filtro,
      tipoAgendamentoOption,
      tipoAgendamentSelect,

    }

  }

}
</script>

<style></style>